import {
  getEventAddOnId,
  getEventAddOnName,
  getEventAddOnUnitPriceAmount,
} from './eventAddOnGetters'
import { eventGetters } from './eventGetters'
import type {
  Cart,
  CartItem,
  GiftcardCartItem,
  EventDateCartItem,
  ShippingCost,
} from '@/composables/types/cart'
import type {
  Coupon,
  GTMItem,
  OrderItem,
  AgnosticCoupon,
  AgnosticTotals,
} from '@/composables/types/checkout'
import type {
  EventAddOn,
  EventDateChildren,
  Price,
} from '~/composables/types/event'

interface IGiftboxPrice {
  amount: number
  currency: string
  formatted: string
}

const fnGetItemValidationRemovableReasons = (): string[] => {
  return ['notAvailable', 'notFound', 'soldOut', 'minAdvance', 'maxAdvance']
}

const fnGetCartItemsLength = (cart: Cart): number => {
  return cart.items.length
}

const getGiftboxPrice = (cart: Cart): IGiftboxPrice => {
  let returnObj: IGiftboxPrice = {
    amount: 100,
    currency: '',
    formatted: '',
  }

  if (cart?.giftboxPrice) {
    returnObj = { ...cart?.giftboxPrice }
  }

  return {
    ...returnObj,
    amount: Number(returnObj.amount) / 100,
  }
}

const getGiftboxPriceAmount = (cart: Cart): string =>
  cart?.giftboxPrice?.amount ?? 0

const getShippingCost = (cart: Cart): ShippingCost | null => {
  return cart?.shippingCost || null
}

const getShippingPrice = (cart: Cart): Price | null => {
  return getShippingCost(cart)?.value || null
}

const getShippingPriceAmount = (cart: Cart): number => {
  const shippingCost = getShippingCost(cart)
  return shippingCost ? Number(shippingCost.value.amount) / 100 : 0
}

const getTotalItems = (cart: Cart): number => {
  return cart?.items?.length || 0
}

const getFormattedPrice = (price: number): string => {
  return ''
}

const getCoupons = (cart: Cart): AgnosticCoupon[] => {
  return []
}

const getDiscount = (coupon: Coupon): any => {
  return coupon?.discount || 0
}

const isGiftboxEnabled = (cart: Cart): boolean => {
  return cart?.isGiftboxEnabled || false
}

const getItems = (cart: Cart): CartItem[] => {
  return cart?.items || []
}

const getItemPermalink = (item: CartItem): string => {
  return item.permalink || ''
}

const getItemId = (item: CartItem): string => {
  return item.id || ''
}

const getGiftcardId = (item: CartItem): string => {
  return item.giftcardId || item.id || ''
}

const getEventDescriptionId = (item: CartItem): string => {
  return item.eventDescriptionId || ''
}

const getItemIdForGtm = (item: CartItem): string => {
  // for GA we want the event description ID's
  if (item.type === 'GIFT_CARD' && !getEventDescriptionId(item)) {
    const price = Number(item?.price?.amount) / 100
    return 'giftcard-' + price
  }
  return getEventDescriptionId(item) || ''
}

const isGiftcard = (item: CartItem): boolean => {
  return item.type === 'GIFT_CARD'
}

const isValueBasedGiftcard = (item: CartItem): boolean => {
  return item?.type === 'GIFT_CARD' && !item?.id && !item.eventDescriptionId
}

const getGiftcardType = (item: CartItem): string | undefined => {
  return item?.giftcardType || undefined
}

const hasGiftcard = (cart: Cart): boolean => {
  const length = cart.items.length

  for (let i = 0; i < length; i++) {
    if (cart.items[i].type === 'GIFT_CARD') {
      return true
    }
  }

  return false
}

const getItemsForOrder = (cart: Cart): OrderItem[] => {
  const length = cart?.items?.length
  const items: OrderItem[] = []

  for (let i = 0; i < length; i++) {
    const item = cart.items[i]

    if (item) {
      if (item.type === 'EVENT') {
        items.push({
          type: 'EVENT',
          product: item.id,
          quantity: item.quantity,
          private: (item as EventDateCartItem).private,
          supplier: eventGetters.getSupplierName(item) || '',
        })
      }

      if (item.type === 'GIFT_CARD') {
        const supplierId = item.supplierId
        items.push({
          type: 'GIFT_CARD',
          event: item.id,
          quantity: item.quantity,
          value: item.price?.amount,
          ...(supplierId && { supplier_id: item.supplierId }),
          ship: getGiftcardAddon(item) !== 'PDF',
          shipType: getGiftcardAddon(item) !== 'PDF' && getGiftcardAddon(item),
          supplier: eventGetters.getSupplierName(item) || 'konfetti',
        })
      }
    }
  }

  return items
}

const getItemsForGtm = (cart: Cart): GTMItem[] => {
  const length = cart.items.length
  const items: GTMItem[] = []

  let index = 0

  for (let i = 0; i < length; i++) {
    const item = cart.items[i]
    const price = getUnitItemPrice(item)
    if (item.type === 'GIFT_CARD') {
      items.push({
        index,
        item_id: getItemIdForGtm(item),
        item_name: item.title,
        item_category: getItemMainCategory(item),
        item_variant: getItemVariantForGtm(item),
        item_brand: item.supplier || 'konfetti',
        quantity: item.quantity,
        price: price?.toFixed(2) || price,
        ship: getGiftcardAddon(item) !== 'PDF',
        shipType: getGiftcardAddon(item) !== 'PDF' && getGiftcardAddon(item),
      })
    } else {
      items.push({
        index,
        item_id: getItemIdForGtm(item),
        item_name: item.title,
        item_category: getItemMainCategory(item),
        item_variant: getItemVariantForGtm(item),
        item_brand: item.supplier,
        quantity: item.quantity,
        price: price?.toFixed(2) || price,
      })
    }

    const addon = getItemAddon(item)

    if (addon) {
      const addonPrice = Number(
        (getEventAddOnUnitPriceAmount(addon) || 0) / 100
      )
      index++
      items.push({
        index,
        item_id: `addon-${getEventAddOnId(addon)}` || '',
        item_name: getEventAddOnName(addon) || '',
        item_category: 'ADD_ON',
        item_variant: 'ADD_ON',
        quantity: item.quantity,
        price: addonPrice?.toFixed(2) || addonPrice,
      })
    }
    index++
  }

  return items
}

const getItemMainCategory = (item: CartItem): string => {
  if (item.type === 'GIFT_CARD') {
    return 'giftcard'
  }
  return item.category || ''
}

const getItemVariantForGtm = (item: CartItem): string => {
  if (item.attendanceMode?.toUpperCase() === 'NATIONWIDE') {
    return 'KIT'
  }

  if (item.type === 'GIFT_CARD') {
    return item.type
  }

  if (item.private === true) {
    return 'PRIVATE_EVENT'
  }
  // default will be "EVENT"
  return item.type
}

const getCartHasItem = (cart: Cart, id: string): boolean => {
  return cart.items.some((item: CartItem) => item.id === id)
}

const getItemName = (item: CartItem): string => {
  return item.title || ''
}

const getItemImage = (item: CartItem): string => {
  return item.thumbnail || ''
}

const getItemAttendanceMode = (item: CartItem): string | undefined => {
  return item.attendanceMode || undefined
}

const getItemPrice = (item: CartItem): number => {
  const privateMinTickets = Number(item?.privateMinTickets) || 1
  const qty =
    item.private && privateMinTickets > item.quantity
      ? item.privateMinTickets
      : item.quantity

  if (!qty) return 0

  return (Number(item?.price?.amount) * qty) / 100
}

export const getAllAddonsForOrder = (
  cart: Cart
): { quantity: number; addon: string; parent: string; type: string }[] => {
  return cart.items
    .filter((item) => item.addOn)
    .map((item) => ({
      addon: item.addOn!.id,
      type: item.addOn!.inventory_type,
      quantity: item.quantity,
      parent: item.id,
    }))
}

const getFirstAddOnName = (cart: Cart): string => {
  const firstItemWithAddOn = cart?.items?.find((item) => item?.addOn)
  return firstItemWithAddOn?.addOn?.name
}
const getAddOnPrice = (item: EventDateCartItem): number => {
  const privateMinTickets = Number(item?.privateMinTickets) || 1

  if (!item.quantity) return 0

  return (Number(item?.addOn?.unit_price?.amount) * item?.quantity) / 100
}

const getTotalAddOnPrice = (cart: Cart) =>
  cart?.items.reduce((total, item) => {
    if (item?.addOn && item?.addOn?.unit_price) {
      return (
        total + Number(item?.addOn?.unit_price.amount / 100) * item.quantity
      )
    }
    return total
  }, 0)

const getUnitItemPrice = (item: CartItem): number => {
  const privateMinTickets = item?.privateMinTickets || 1
  const price = Number(item?.price?.amount)
  const qty = item?.quantity

  if (item?.dateTypeToBeBooked === 'PRIVATE') {
    if (privateMinTickets > qty) {
      return (price * privateMinTickets) / qty / 100
    }
  }

  return price / 100
}

const getItemPriceAmount = (item: CartItem): number => {
  return Number(item.price.amount)
}

const getItemQty = (item: CartItem): number => {
  return item.quantity || 1
}

const getAvailableStock = (item: CartItem): number => {
  if (item.type === 'GIFT_CARD') {
    return 999999
  }

  if (item.private) {
    return item?.privateMaxTickets || item.availableTicketsQuantity || 1
  }

  return item.availableTicketsQuantity || 1
}

const isPrivate = (item: CartItem): boolean => {
  return item.private || false
}

const getCartTotalPrice = (cart: Cart, isTotal: boolean): number => {
  if (!cart || !cart.items) {
    return 0
  }

  let total = 0
  for (let i = 0; i < cart?.items?.length; i++) {
    const item = cart.items[i]

    total += getItemPrice(item)

    if ((item as EventDateCartItem)?.addOn) {
      total += getAddOnPrice(item as EventDateCartItem)
    }

    if (isGiftcard(item)) {
      total += getGiftcardAddonTotalPrice(item, isGiftboxEnabled(cart))
    }
  }

  if (cart.isGiftboxEnabled && isTotal) {
    total += getShippingPriceAmount(cart)
  }

  // cover the corner cases of JS mathematics, like 0.1 + 0.2 = 0.30000000000000004
  // using + to convert to number
  return +total.toFixed(2)
}

const getTotals = (cart: Cart): AgnosticTotals => {
  return {
    total: getCartTotalPrice(cart, true),
    subtotal: getCartTotalPrice(cart, false),
    special: undefined,
  }
}

const getItemAddon = (cartItem: CartItem): EventAddOn | undefined => {
  return cartItem?.addOn
}

// get label of selected giftcard addon
const getGiftcardAddon = (
  cartItem: GiftcardCartItem
): EventAddOn | undefined => {
  return cartItem?.giftcardAddon
}

// calculate total giftcard addon price for single card
const getGiftcardAddonTotalPrice = (
  item: GiftcardCartItem,
  isGiftboxEnabled: boolean = false
): number => {
  if (getGiftcardAddonSinglePrice(item, isGiftboxEnabled) === 0) return 0

  // formatted price of single item, like 2.95 instead of 295
  const amount =
    Number(getGiftcardAddonSinglePrice(item, isGiftboxEnabled)) / 100

  // if giftcard is value based, multiply by quantity of added cards
  return isValueBasedGiftcard(item) ? amount * item.quantity : amount
}

// get price of giftcard addon using giftcard label
const getGiftcardAddonSinglePrice = (
  item: GiftcardCartItem,
  isGiftboxEnabled: boolean = false
): number => {
  const addon = getGiftcardAddon(item)
  // TODO delete after week
  if (!addon && !isGiftboxEnabled) return 0 // LEGACY FALLBACK
  if (!addon && isGiftboxEnabled) return getGiftcardListAddon[0]?.price // LEGACY FALLBACK

  return getGiftcardListAddon.find((item) => item.id === addon)?.price
}

// map of available addons with legacy option
const getGiftcardListAddon = [
  {
    id: 'LEGACY',
    price: 295,
    is_visible: false,
  },
  {
    id: 'PDF',
    price: 0,
    image: '/_nuxt3-static/giftcard/giftcard-pdf.png',
    is_visible: true,
  },
  {
    id: 'CARD',
    price: 295,
    image: '/_nuxt3-static/giftcard/envelope.png',
    is_visible: true,
  },
  {
    id: 'BOX',
    price: 495,
    image: '/_nuxt3-static/giftcard/box-general.png',
    is_visible: true,
  },
]

export const cartGetters = {
  getTotals,
  getShippingPrice,
  getItems,
  getItemName,
  getItemAttendanceMode,
  getItemImage,
  getItemPrice,
  getFirstAddOnName,
  getAddOnPrice,
  getTotalAddOnPrice,
  getItemId,
  getGiftcardId,
  getGiftcardAddon,
  getGiftcardListAddon,
  getItemQty,
  getFormattedPrice,
  getTotalItems,
  getCoupons,
  getDiscount,
  getAvailableStock,
  getCartHasItem,
  getItemPermalink,
  getCartTotalPrice,
  getItemsForOrder,
  isGiftboxEnabled,
  getGiftboxPrice,
  getGiftboxPriceAmount,
  getGiftcardAddonTotalPrice,
  getGiftcardAddonSinglePrice,
  isGiftcard,
  getGiftcardType,
  hasGiftcard,
  getItemsForGtm,
  getItemMainCategory,
  fnGetItemValidationRemovableReasons,
  fnGetCartItemsLength,
  isPrivate,
  getUnitItemPrice,
  getItemPriceAmount,
  getItemVariantForGtm,
  getItemIdForGtm,
  getEventDescriptionId,
  getAllAddonsForOrder,
  getItemAddon,
}
